import React from 'react';
import img01 from '../../images/components/features/img_01.svg';
import img02 from '../../images/components/features/img_02.svg';
import img03 from '../../images/components/features/img_03.svg';
import img04 from '../../images/components/features/img_04.svg';
import img05 from '../../images/components/features/img_05.svg';

function FeatureBannner(props) {
    return (
        <div className="banner_feature">
            <div className="item_image">
                <h4>Featured In:</h4>
            </div>
            <div className="item_image">
                <img src={img04} alt="iamg_04" className="img-fluid"/>
            </div>
            <div className="item_image">
                <img src={img03} alt="iamg_03" className="img-fluid"/>
            </div>
            <div className="item_image">
                <img src={img02} alt="iamg_02" className="img-fluid"/>
            </div>
            <div className="item_image">
                <img src={img01} alt="iamg_01" className="img-fluid"/>
            </div>
            <div className="item_image">
                <img src={img05} alt="iamg_05" className="img-fluid"/>
            </div>
        </div>
    );
}

export default FeatureBannner;